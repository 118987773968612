//== Color
$color-light-grey: #f7f6f4;
$color-medium-grey: #eae7e4;
$color-dark-grey: #ada8a2;

$cool-light-grey: #f7f7f7;
$cool-light-grey-caps: #f7f7f7;
$cool-medium-grey: #e6e6e6;
$cool-dark-grey: #a9a9a9;

$color-light-blue: #384451;
$color-medium-blue: #2b343e;
$color-dark-blue: #1e252c;

$color-light-green: #3d4542;
$color-medium-green: #2e3432;
$color-dark-green: #1c201e;

$color-light-plump: #4f3a3c;
$color-medium-plump: #3f1519;
$color-dark-plump: #301316;
$color-medium-light-plump: #570000;
//== Text Color
$text-light-grey: #6f6f6f;
$text-medium-grey: #868686;
$text-medium-dark-grey: #5d5d5d;
$text-medium-dark-grey-small: #5d5d5d;
$text-dark-grey: #424242;
$text-medium-light-grey: #7e7474;

$text-medium-black: #212121;
$color-white: #ffffff;

$white: #ffffff;
$black: #000000;
$charcoal: #212121;
$eclipse: #404040;
$very-light-grey: #cbcbcb;
$slate: #494949;
$dark-gray: #757575;
$medium-gray: #989898;
$light-gray: #cdcdcd;
$extra-light-gray: #f1f1f1;
$white-smoke: #efefef;
$half-white: #f9f9f9;
$light-white: #e6e6e6;
$accent-blue: #e3ebf1;
$accent-gray-light: #eff2f5;
$primary-blue: #022e49;
$error-color: #ff2a2a;
$extra-light-white: #fafafa;
$maroon: #570000;
$foundation: #6c6966;
$text-light-dark-grey: #6b6b6b;
$dark-grey-medium: #d8d5d3;
$color-medium-blue-cap: #2b343e;
$cool-dark-grey-cap: #a9a9a9;
$input-border-color: #9a9a9a;
$text-light-grey-cap: #6f6f6f;
$white-cap: #ffffff;
$color-charcoal: #212121;
$color-extra-light-gray: #e6e6e6;
$header-shadow: rgba(0, 0, 0, 0.07);
$sort-drop-shadow: rgba(0, 0, 0, 0.15);
$tag-border-color: rgba(56, 68, 81, 0.3);
$annsacks-add-to-cart-btn: #a77b7b;
$color-dark-gray: #757575;
$color-red-alert: #d10000;
$cool-grey-medium: #e8e8e8;
$minor-light-grey: #f4f4f4;

$filter-border-line: #64605b;
$primary-blue-loader:#345366;

// Normal mode
$color-foreground: #202020;
$color-background: #ffffff;
$color-link: #2020e0;

// Dark mode
$color-foreground-dark: invert($color-foreground);
$color-background-dark: invert($color-background);
$color-link-dark: invert($color-link);

//== Font
$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size: 16px;
$font-height: 1.5;

// Font family variable start here
$playfair-regular: "PlayfairDisplay-Regular";
$lato-regular: "Lato-Regular";
$lato-bold: "Lato-Bold";
$font-lato: "Lato";

// Kohler Font family variable start here
$helvetica-light: "Helvetica Now Text W05 Light";
$helvetica-regular: "Helvetica Now Text W05 Regular";
$helvetica-medium: "Helvetica Now Text W05 Medium";
$helvetica-bold: "Helvetica Now Text W05 Bold";

//Annsack
$helvetica-neue-medium: "HelveticaNeueLTW01-67MdCn";
$helvetica-neue-bold: "HelveticaNeueLTW01-77BoldCn";
$font-family-kohler: "KohlerIcons";
$font-family-KohlerSupplementalIcons: "KohlerSupplementalIcons";
$font-family-fa: "FontAwesome5ProLight";
$icon-moon: "icomoon";
$playfair-display-regular: "Playfair Display Regular";

$lato-regular: "Lato-Regular";
$font-regular-base: $lato-regular;
$color-light-gray: #f2f2f2;
$color-accent-blue-a: rgba(24, 104, 165, 0.1);
$font-lato: $lato-regular;
