@import "@/styles/base/_colors";

.authUtilityNavWrapper {
  :global {
    .auth-nav {
      cursor: pointer;
      display: inline-block;

      &__user {
        font-family: "Helvetica Now Text W05 Bold";
        font-size: 13px;
        line-height: 1;
        letter-spacing: -0.32px;
        color: #fafafa;
        text-transform: capitalize;
        display: flex;
        justify-content: flex-start;
        word-break: break-all;
      }

      &__dropdown {
        display: none;
        background: $color-charcoal;
      }

      &__dropdown {
        position: absolute;
        background: $color-charcoal;
        padding: 20px;

        a {
          font-family: "Helvetica Now Text W05 Light" !important;
          font-size: 14px !important;
          line-height: 1.57 !important;
          letter-spacing: -0.65px !important;
          color: $color-white !important;
          white-space: nowrap !important;
          padding-bottom: 5px !important;

          &:hover {
            text-decoration: none !important;
          }
        }
      }

      &__menu {
        display: none;
        position: absolute;
        left: 0;
        z-index: 99;
        width: 100vw;
        background: $color-charcoal;
        padding: 67px 0px 85px 0px;
        margin-top: 8px;
        color: $color-white;
        max-width: 100%;
        .container {
          max-width: 1380px;
        }
        &-open {
          display: block;
        }
      }

      &--active {
        .auth-nav__menu {
          display: block;
        }
      }

      &__menu-wrap {
        display: flex;
      }

      &__menu-title {
        width: 33.75rem;
        word-break: break-word;
        font-family: "Helvetica Now Text W05 Light";
        font-size: 3.125rem;
        line-height: 3.375rem;
        letter-spacing: -0.188rem;
        color: $color-white;
        border-right: 2px solid $color-slate;
      }

      &__menu-items {
        margin-left: 7.5rem;
        column-count: 1;
        column-gap: 60px;
      }

      p {
        margin: 0;
      }
      &__sign-in-menu {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-extra-light-gray;
      }
      &__create-account {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-extra-light-gray;
        display: block;
        margin-top: 15px;
      }
      &__sign-in-mobile {
        display: flex;
      }
      &__sign-in-avatar {
        margin-right: 12px;
      }
      &__sign-in-right-arrow {
        position: absolute;
      }
      &__close-icon {
        color: $color-white;
        font-size: 16px;
        position: relative;
        z-index: 999;
        top: 0;
      }
    }
    .auth-nav__menu-expand .auth-nav__menu-items {
      column-count: 2;
    }
    .auth-nav .auth-nav__menu .auth-nav__menu-items a,
    .auth-nav .auth-nav__menu-wrap .auth-nav__menu-items a {
      font-family: "Helvetica Now Text W05 Light";
      font-size: 26px;
      min-width: 15rem;
      line-height: 1.31;
      letter-spacing: -1.55px;
      color: $color-white;

      &:hover {
        text-decoration: none;
      }
    }

    .auth-nav {
      .auth-nav__menu {
        .auth-nav__menu-items {
          position: relative;
          .sign-out {
            position: absolute;
            top: 5px;
            left: 100%;
            min-width: 11.25rem;
            a {
              color: $color-white;
              font-family: "Helvetica Now Text W05 Light";
              font-size: 16px;
              letter-spacing: -0.55px;
              line-height: 24px;
            }
          }
        }
      }
    }
    @media only screen and (min-width: 1201px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
      .auth-nav {
        &__menu-items {
          margin-left: 75px;
          .sign-out {
            left: 95%;
          }
        }
      }
    }
    @media only screen and (min-width: 1201px) {
      .auth-nav {
        &__menu-mobile-back-menu {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 1200px) and (orientation: landscape),
      only screen and (max-width: 1200px) {
      .auth-nav {
        .kf-react-container {
          padding: 0 !important;
        }
        &__sign-in-right-arrow {
          right: 32px;
        }
        &__user {
          font-size: 20px;
        }
        .auth-nav__menu-items {
          column-gap: 0px;
          margin-left: 0px;
          column-count: 1;
        }
        &--active {
          .auth-nav__menu {
            top: 43px;
            position: fixed;
            height: 100vh;
            transition: 0.5s ease-in-out;
            padding: 38px 16px;
            &-mobile-back-menu {
              display: flex;
            }
            &-expand .auth-nav__menu-wrap {
              display: block;
              padding-top: 20px;
            }
          }
        }
        .auth-nav__menu .auth-nav__menu-items .sign-out {
          position: relative;
          left: 0px;
          a {
            color: $color-light-gray;
          }
        }
        &__menu {
          display: none;
          &-title,
          &-expand .auth-nav__menu-wrap {
            display: none;
          }
          &-wrap {
            display: block;
          }
          &-mobile-back-menu {
            display: none;
            span {
              font-family: "Helvetica Now Text W05 Light";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              color: $color-extra-light-gray;
              margin-left: 5px;
            }
          }
        }
      }

      .auth-nav .auth-nav__menu .auth-nav__menu-items a,
      .auth-nav .auth-nav__menu-wrap .auth-nav__menu-items a {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-light-gray;

        &:hover {
          text-decoration: none;
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .auth-nav {
        &__user {
          font-size: 20px;
        }
        .auth-nav__menu .auth-nav__menu-items {
          column-gap: 0px;
          column-count: 1;
          margin-left: 18px;
        }
        &--active {
          .auth-nav__menu.auth-nav__menu-expand .auth-nav__menu-items a {
            font-size: 30px;
            line-height: 38px;
            letter-spacing: -1.9px;
            color: $color-white;
            margin-bottom: 10px;
          }
          .auth-nav__menu.auth-nav__menu-expand
            .auth-nav__menu-items
            .sign-out
            a {
            font-size: 20px;
            margin-top: 20px;
            color: $color-light-gray;
          }
          .auth-nav__menu {
            top: 57px;
            position: fixed;
            height: 100vh;
            transition: 0.5s ease-in-out;
            padding: 30px 2px;
            margin-top: 0;
            &-mobile-back-menu {
              display: flex;
            }
            &-expand .auth-nav__menu-wrap {
              display: block;
              padding-top: 24px;
            }
          }
        }
        .auth-nav__menu .auth-nav__menu-items .sign-out {
          position: relative;
          left: 0px;
        }
        &__menu {
          display: none;
          padding: 0px;
          bottom: 20px;
          &-title,
          &-expand .auth-nav__menu-wrap {
            display: none;
          }
          &-wrap {
            display: block;
          }
          &-mobile-back-menu {
            display: none;
            span {
              font-family: "Helvetica Now Text W05 Light";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              color: $color-extra-light-gray;
              margin-left: 5px;
            }
          }
        }
      }

      .auth-nav .auth-nav__menu .auth-nav__menu-items a,
      .auth-nav .auth-nav__menu-wrap .auth-nav__menu-items a {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-light-gray;

        &:hover {
          text-decoration: none;
        }
      }

      .auth-nav__menu-items span {
        display: block;
        line-height: 28px;
      }
    }

    @media only screen and (max-width: 1200px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px) {
      .auth-nav__menu-mobile-back-menu {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    @media only screen and (max-width: 767px) and (orientation: portrait) {
      .auth-nav__menu-mobile-back-menu {
        padding-left: 18px;
        padding-right: 18px;
      }
      .auth-nav__sign-in-right-arrow {
        right: 18px;
      }
    }

    @media only screen and (max-width: 1023px) and (orientation: landscape) {
      .auth-nav {
        &--active {
          .auth-nav__menu {
            overflow: scroll;
            height: 85vh;
          }
        }
      }
    }

    @media print {
      .header-container,
      .utilitynavheader {
        display: none !important;
      }
    }
  }
}
