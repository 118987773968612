@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.autoSuggetionWrapper {
  :global {
    .auto-suggestion {
      &__input-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      &__border-bottom {
        border: none;
        outline: none;
        border-bottom: 1px solid $color-dark-gray;
      }
      &__border-all {
        border: 1px solid $color-dark-gray;
      }
      &__input {
        width: 100%;
        font-family: $font-light;
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -1.5px;
        color: $color-slate;
        padding: 0px 0px 15px 0;
        border: none;
      }
      &__input-field-prefix {
        margin-right: 25px;
        img {
          position: absolute;
          left: 0;
          top: 47%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      &__input-field-suffix {
        img {
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      &__dropdown {
        position: relative;
        top: 0;
        background: $color-white;
        z-index: 2;
        &.active {
          .auto-suggestion__dropdown-items {
            visibility: visible;
            height: auto;
            max-height: 290px;
            opacity: 1;
            transition: max-height 0.3s ease;
            width: 100%;
            border: 1px solid $color-white;
            background: $color-white;
            overflow-y: auto;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 1px grey;
              border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background: $color-dark-gray;
              border-radius: 5px;
            }
          }
        }
      }

      &__dropdown-items {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        max-height: 0px;
        padding: 16px 0 15px;
        margin-left: 0;
      }

      &__dropdown-item {
        cursor: pointer;
        padding: 10px 18px;

        &:hover {
          color: $color-white;
          background: $color-kohler-blue;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $color-white;
        }
      }
    }

    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px),
      only screen and (max-width: 767px) {
      .auto-suggestion {
        &__input {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
          color: $color-charcoal;
        }
      }
    }
  }
}
