@import "@/styles/base/_colors";

.productCardWrapper {
  :global {
    @mixin font-style-mixin {
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: -0.0187rem;
    }
    .product-card-loader {
      &__container {
        width: 425px;
      }
    }
    .inspired-selling-module {
      &__card-container {
        width: 100%;
        position: relative;

        .inspired-selling-module {
          &__card {
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            overflow: hidden;

            .image {
              &__container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                aspect-ratio: 1/1;

                .product-card {
                  &__image {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: 50% 50%;
                    object-position: 50% 50%;
                  }
                }
              }
            }
            .product-tile__share {
              opacity: 1;

              .product-tile__share-icon {
                background-color: transparent;
              }
            }
          }
        }

        .card-text {
          &__container {
            .card-details {
              &__container {
                display: flex;
                flex-direction: row;
                align-items: normal;
                justify-content: space-between;
                margin: 15px 0 5px 0;
                @media screen and (max-width: 1038px) {
                  margin: 10px 0 0 0;
                  @media screen and (max-width: 639px) {
                    margin: 10px 14.5px 0 0;
                  }
                }

                p {
                  font-family: "Helvetica Now Text W05 Medium", sans-serif;
                  font-size: 1rem;
                  letter-spacing: -0.0456rem;
                  line-height: 1.375rem;
                  @media screen and (max-width: 1038px) {
                    @include font-style-mixin;
                  }
                }
              }
            }

            .card-details-descriprion {
              &__container {
                p {
                  font-family: "Helvetica Now Text W05 Regular", sans-serif;
                  font-size: 1rem;
                  letter-spacing: -0.0456rem;
                  line-height: 1.375rem;
                  @media screen and (max-width: 1038px) {
                    @include font-style-mixin;
                    letter-spacing: -0.0281rem;
                    margin-right: 40px;
                  }
                  @media screen and (max-width: 639px) {
                    margin-right: 14.5px;
                  }
                }
              }

              &__card-price {
                color: $color-slate;
                font-family: "Helvetica Now Text W05 Medium", sans-serif;
                font-size: 1rem;
                letter-spacing: -0.0219rem;
                line-height: 1.5rem;
              }
            }
            .card-details {
              &__card-price {
                p {
                  font-family: "Helvetica Now Text W05 Medium", sans-serif;
                  @media screen and (max-width: 1038px) {
                    @include font-style-mixin;
                  }
                  @media screen and (max-width: 639px) {
                    margin-right: 14.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
