@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.curatedProduct {
  :global {
    .curated-product-recommendation {
      @media screen and (min-width: 767px) and (max-width: 1920px) {
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 25px;
      }
      @media screen and (min-width: 1920px) {
        padding: 0 2.6rem;
        max-width: 20in;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 25px;
      }
      display: flex;
      overflow-x: auto;
      &__card-list {
        display: flex;
      }
      &__product-card {
        width: 280px;
        padding-top: 20px;
        margin-right: 20px;
        padding-bottom: 25px;
        @media screen and (min-width: 767px) and (max-width: 1204px) {
          width: 339px;
          margin-top: 20px;
          margin-right: 20px;
        }
        @media screen and (max-width: 768px) {
          width: 166px;
          margin-right: 15px;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        padding-left: 10px;
        padding-right: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px $color-light-gray;
        border-radius: 5px;
        background-color: $color-light-gray;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-dark-gray;
        border-radius: 5px;
      }
      &__img {
        width: 280px;
        height: 280px;
        @media screen and (min-width: 767px) and (max-width: 1024px) {
          width: 339px;
          height: 339px;
        }
        @media screen and (max-width: 768px) {
          width: 166px;
          height: 166px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__product-name {
        font-family: $font-medium;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.35px;
        color: $color-slate;
        padding-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &__product-sku {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-top: 10px;
      }
      &__product-price {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-slate;
        padding-top: 10px;
      }
      &__discounted-price {
        display: flex;
        padding-top: 10px;
        .price-with-discount {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-slate;
        }
        .original-price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          margin-left: 5px;
          text-decoration: line-through;
        }
      }
      &__out-of-stock {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $color-dark-gray;
        padding-top: 10px;
      }
      &__add-to-cart {
        width: 133px;
        height: 40px;
        margin-top: 10px;

        .button {
          width: 133px;
          height: 40px;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.3px;
          padding: 0 !important;
          text-align: center;
        }
      }
    }
  }
}
