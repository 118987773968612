@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.reCatichWrapper {
  :global {
    .recaptcha-links {
      font-family: $font-regular;
      color: $color-dark-gray;
      letter-spacing: -0.45px;
      line-height: 22px;
      font-size: 14px;
      .gpolicies {
        text-decoration: underline;
        color: $color-dark-gray;
        &:hover {
          color: $color-black;
        }
      }
    }
  }
}
