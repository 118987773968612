@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.wrapper {
  :global {
    @media print {
      footer {
        display: none !important;
      }
    }
    .modal-popup-content {
      padding: 0;
      max-width: 500px;
      .modal-popup-body {
        padding: 35px 40px 50px;
      }
    }
    .newsletter__thanks {
      * {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        font-family: $font-regular;
        margin-bottom: 15px;
        display: inline-block;
        color: $color-slate;

        @include breakpoint(tab) {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 30px;
        }
        @include breakpoint(mobile) {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }
    .newsletter__success {
      * {
        font-family: $font-regular;
        font-size: 1pc;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        a {
          text-decoration: underline;
          color: $color-dark-gray;
          &:hover {
            text-decoration: none;
          }
        }
        @include breakpoint(tab) {
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 50px;
        }
        @include breakpoint(mobile) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 40px;
        }
      }
    }
    .newsletter__content {
      margin-top: 35px;
      .kf-react-button {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2.5px;
        // font-family: $lato-bold;
        text-transform: uppercase;
        @include breakpoint(tab) {
          font-size: 11px;
          line-height: 10px;
        }
        @include breakpoint(mobile) {
          font-size: 11px;
          line-height: 10px;
        }
      }
    }
    footer {
      .gbh-footer-newsletter-nav {
        .recaptcha-links {
          display: none;
        }
      }
    }
  }
}
// Animation:
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-moz-document url-prefix() {
  footer {
    .gbh-footer-news-letter {
      .form-group {
        .form-control {
          padding: 1.625rem 0 0;
          &:-moz-placeholder {
            display: none;
          }
        }
        .btn {
          top: 27px;
        }
      }
    }
  }
}
