@mixin ann-describtion(
  $describe-font-size,
  $describe-font-fam,
  $describe-line-height
) {
  font-size: $describe-font-size;
  font-family: $describe-font-fam;
  line-height: $describe-line-height;
}
@mixin annsacks-desk(
  $property,
  $min,
  $max,
  $start: 992,
  $end: 1920,
  $clip: true,
  $clipAtStart: true,
  $clipAtEnd: true
) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

//   ex:
//   @include annsacks-desk(margin-right, 35, 250);

@mixin text-style($fs, $lh, $ls) {
  font-size: $fs;
  line-height: $lh;
  letter-spacing: $ls;
}

@mixin annsacks-desk($property, $min, $max, $start: 992, $end: 1920, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

//   ex:
//   @include annsacks-desk(margin-right, 35, 250);

@mixin annsacks-mobile($property, $min, $max, $start: 375, $end: 992, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
$multiplier: ($max - $min) / ($end - $start) * 100;
$adder: ($min * $end - $max * $start) / ($end - $start);
$formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
@if $clip and $clipAtStart {
  @media (max-width: #{$start + 0px}) {
    #{$property}: $min + 0px;
  }
}
@if $clip and $clipAtEnd {
  @media (min-width: #{$end + 0px}) {
    #{$property}: $max + 0px;
  }
}
#{$property}: $formula;
}