@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.findAStoreUtilNavWrapper {
  :global {
    .findstore-util-nav__activeMap {
      overflow: hidden;
      transform: translateY(-100%);
      transition: 0.5s ease-in-out;
      height: 0;
      position: absolute !important;
      left: 0;
      right: 0;
      transition-delay: 0.1s;
    }

    .findstore-util-nav__input-container {
      .auto-suggestion {
        &__input {
          width: 100%;
          color: $color-white;
          background-color: $color-charcoal;

          @media only screen and (min-width: 1024px) and (max-width: 1300px) {
            font-size: 26px;
          }
        }

        &__dropdown-items {
          flex-direction: column;
        }

        &__dropdown-item {
          cursor: pointer;
          padding: 10px 18px !important;

          &:hover {
            color: $color-white;
            background: $color-charcoal;
            width: 100%;
          }
        }

        &__dropdown {
          position: relative;
          top: 0;
          background: $color-white;
          z-index: 2;

          &.active {
            .auto-suggestion__dropdown-items {
              max-height: 151px;
            }
          }
        }

        &__border-bottom {
          border-bottom: none;

          @media only screen and (max-width: 767px) {
            border-bottom: 1px solid $color-dark-gray;
          }
        }

        @media only screen and (max-width: 860px) and (orientation: landscape),
          only screen and (min-width: 768px) and (max-width: 991px),
          only screen and (max-width: 767px) {
          &__dropdown {
            &.active {
              .auto-suggestion__dropdown-items {
                width: 475px;
                height: 151px;
                text-align: left;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px),
      only screen and (max-width: 767px) {
      .header-container
        .cmp-container
        .utilitynavheader
        .gbh-utility-navbar
        .findstore-util-nav__input-container
        ul
        li {
        display: block;
      }
    }

    @media screen and (min-width: 1201px) {
      .gbh-find-a-store {
        &.active {
          height: initial !important;
        }

        &:after {
          pointer-events: none;
        }
      }
    }

    .findstore-util-nav {
      position: relative;

      &__zipcode-input-error .input-field__border {
        border-color: $color-error-red;
      }

      &__zipcode-error {
        margin-top: 10px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.3px;
        color: $color-error-red;
      }

      &__content {
        background-color: $color-charcoal;
        padding: 70px 0px;

        .findstore-util-nav__address-container {
          padding-right: 60px;
          padding-left: 60px;
        }

        .findstore-util-nav__loc-search-container {
          padding-right: 20px;
          padding-left: 116px;
        }
      }

      .findstore-util-nav__search-utility-container {
        border-right: 1px solid $color-dark-gray;
        padding-left: 0;

        div.findstore-util-nav__zipcode-content > *,
        .findstore-util-nav__zipcode-content {
          font-family: $font-light;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-light-gray;
        }

        div.findstore-util-nav__location-content > *,
        .findstore-util-nav__location-content {
          font-family: $font-light;
          font-size: 50px;
          line-height: 1.08;
          letter-spacing: -3px;
          color: $color-white;
        }
      }

      &__location-content {
        padding: 0px 200px 40px 0px;
      }

      &__zipcode-content {
        padding: 0px 0px 25px 0px;
        text-align: left;

        @media only screen and (min-width: 1024px) and (max-width: 1300px) {
          padding: 0px 0px 25px 45px;
        }
      }

      &__link-container {
        padding-top: 25px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media only screen and (max-width: 990px) {
          padding-bottom: 50px;
          padding-top: 30px;
        }
      }

      &__location-link {
        font-family: $font-light;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-light-gray;
        text-decoration: underline;
        cursor: pointer;
      }

      &__submit {
        background-color: $color-white;
        border-radius: 0px;
        height: 40px;
        min-width: 105px;
        border: none;
        font-family: $font-medium;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.25px;
        text-align: center;
        color: $color-slate;
      }

      &__input-container {
        display: flex;
        border-bottom: 1px $color-slate solid;
        width: 100%;
        justify-content: space-between;
      }

      &__input-control {
        caret-color: $color-white;
        background-color: $color-charcoal;
        color: $color-white;
        font-family: $font-light;
        font-size: 36px;
        line-height: 1.22;
        letter-spacing: -1.7px;
        border: none;
        padding-bottom: 25px;
        width: 82%;
        padding-right: 15px;

        &::placeholder {
          color: $color-white;
        }
      }

      &__input-disabled-state {
        .findstore-util-nav__input-control {
          color: $color-dark-gray;
          opacity: 0.5;

          &::placeholder {
            color: $color-dark-gray;
          }
        }

        .findstore-util-nav__submit {
          color: $color-white;
          border: 1px solid $color-white;
          background-color: transparent;
          opacity: 0.5;

          &:focus-visible {
            outline: 2px dotted $color-dark-gray !important;
          }
        }
      }

      .findstore-util-nav__submit:hover {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }

      .findstore-util-nav__submit:focus,
      .findstore-util-nav__submit:active {
        transform: scale(0.98);
      }

      &__store {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.35px;
        display: block;
        margin-bottom: 7px;
      }

      &__signStore {
        font-size: 50px;
        line-height: 1.08;
        letter-spacing: -3px;
        font-family: $font-light;
        display: block;
      }

      .findstore-util-nav__supply-section {
        color: $color-white;
        padding-left: 0;
      }

      &__address-container {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-light-gray;
        padding-top: 40px;

        p {
          color: $color-light-gray;
          letter-spacing: -0.55px;
          line-height: 1.5;
          margin-bottom: 10px;

          span {
            display: block;
          }
        }

        .checkbox {
          margin-bottom: 10px;
          margin-top: 25px;

          input ~ span {
            color: $color-light-gray;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
          }

          input:checked ~ span {
            color: $color-light-gray;
          }

          input ~ .checkbox__border .checkbox__marker {
            .checkbox__marker-icon {
              display: none;
            }
          }

          input:checked ~ .checkbox__border .checkbox__marker {
            background: $color-black;
            border: none;

            .checkbox__marker-icon {
              display: block;
            }
          }
        }
      }

      &__access-utility-container {
        padding: 0px 45px 0px 0px;
        border-right: 1px solid $color-slate;
      }

      &__not-your-store {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.35px;
        color: $color-white;
        font-family: $font-medium;
        margin-bottom: 30px;
        display: block;
      }

      &__view-store-detail {
        color: $color-light-gray;
        text-decoration: underline;
        cursor: pointer;
      }

      .checkbox-wrapper {
        padding-top: 30px;

        input:checked ~ .checkbox-label {
          color: $color-light-gray;
        }

        input:checked {
          background-color: $color-black;
          border: solid 1px $color-black;
        }
      }

      &__mobile-container {
        text-align: left;
        background-color: $color-charcoal;
      }

      &__close {
        display: block;
      }

      &__menu {
        display: flex;

        span {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-extra-light-gray;
          margin-left: 10px;
        }
      }
    }

    .gbh-utility-navbar {
      .findstore-util-nav {
        &__view-link-container {
          .findstore-util-nav__view-store-link {
            font-family: $font-light;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-light-gray;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &__address-container {
          .findstore-util-nav__view-store-detail {
            font-family: $font-light;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-light-gray;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .gbh-nav__findStore,
    .gbh-nav__region {
      padding-right: 20px !important;
    }

    @media only screen and (min-width: 1301px) and (max-width: 1536px) {
      .findstore-util-nav {
        &__content {
          .findstore-util-nav__address-container {
            padding-right: 30px;
            padding-left: 30px;
          }

          .findstore-util-nav__loc-search-container {
            padding-right: 50px;
            padding-left: 70px;
          }
        }

        &__signStore {
          font-size: 39px;
          line-height: 50px;
        }
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
      .findstore-util-nav {
        &__content {
          .findstore-util-nav__address-container {
            padding-right: 25px;
          }

          .findstore-util-nav__loc-search-container {
            padding-right: 50px;
            padding-left: 70px;
          }
        }

        &__signStore {
          font-size: 39px;
          line-height: 50px;
        }
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      .findstore-util-nav {
        .findstore-util-nav__search-utility-container {
          div.findstore-util-nav__location-content > *,
          .findstore-util-nav__location-content {
            font-size: 40px;
          }
        }

        &__content .findstore-util-nav__loc-search-container {
          padding-right: 20px;
          padding-left: 40px;
        }

        &__content .findstore-util-nav__address-container {
          padding-right: 30px;
          padding-left: 30px;
        }

        &__location-content {
          padding: 10px 110px 50px 0px;
        }

        &__input-control {
          font-size: 33px;
        }

        &__mobile-container {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px) {
      .findstore-util-nav {
        .findstore-util-nav__search-utility-container {
          div.findstore-util-nav__location-content > *,
          .findstore-util-nav__location-content {
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -1.9px;
            text-align: left;
          }

          div.findstore-util-nav__zipcode-content > *,
          .findstore-util-nav__zipcode-content {
            padding: 0px;
            text-align: left;
          }
        }

        &__submit {
          width: 204px;
          height: 40px;
        }

        &__container {
          overflow-y: auto;
        }

        &__content {
          padding: 0px 0px 50px;
        }

        &__location-content {
          padding: 0px 0px 20px 0px;
        }

        .findstore-util-nav__search-utility-container {
          border-right: none;
          padding-right: 0px;
        }

        .findstore-util-nav__supply-section {
          padding-right: 0px;
        }

        &__search-utility-container {
          border-right: none;
        }

        &__content {
          .findstore-util-nav__loc-search-container {
            padding-right: 0px;
            padding-left: 0px;
            padding-top: 50px;
          }

          .findstore-util-nav__address-container {
            padding-right: 0px;
            padding-left: 0px;
            text-align: left;
          }
        }

        &__input-container {
          width: 100%;
          padding-top: 0px;
          justify-content: space-between;
        }

        &__input-control {
          font-size: 30px;
          width: 87%;
        }

        &__view-store-link {
          text-decoration: underline;
          color: $color-light-gray;
        }

        &__loc-search-container {
          margin-left: 0px;
          padding-right: 0px;
        }

        &__store {
          margin-bottom: 10px;
        }

        &__supply-section,
        &__address-container {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }

        &__access-utility-container {
          display: block;
          margin: 0px;
          padding: 0px 15px;
          border-right: 0px;
        }

        &__signStore {
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -1.9px;
        }

        &__address-container {
          margin-left: 0px;
          margin-top: 30px;
          padding-top: 0;
        }

        &__link-container {
          width: 100%;
        }

        &__supply-section,
        &__not-your-store {
          text-align: left;
        }

        &__mobile-container {
          padding: 35px 32px 0px;
        }

        &__close {
          margin-bottom: 31px;
        }

        &__menu {
          padding-bottom: 20px;
        }
      }

      .gbh-find-a-store {
        .findstore-util-nav {
          &__view-link-container {
            .findstore-util-nav__view-store-link {
              font-family: $font-light;
              text-decoration: underline;
              padding: 0px;
              font-size: 16px;
            }
          }

          &__address-container {
            .findstore-util-nav__view-store-detail {
              font-family: $font-light;
              padding: 0px;
              font-size: 16px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .findstore-util-nav {
        .findstore-util-nav__search-utility-container {
          div.findstore-util-nav__location-content > *,
          .findstore-util-nav__location-content {
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -1.9px;
            text-align: left;
          }

          div.findstore-util-nav__zipcode-content > *,
          .findstore-util-nav__zipcode-content {
            text-align: left;
          }
        }

        &__submit {
          margin-top: 20px;
        }

        &__container {
          overflow-y: auto;
        }

        .findstore-util-nav__supply-section {
          padding-right: 0;
        }

        &__content {
          padding: 0px 0px 25px;

          .findstore-util-nav__loc-search-container {
            padding: 25px 3px;
          }

          .findstore-util-nav__address-container {
            margin-bottom: 25px;
            text-align: left;
            padding-right: 0px;
            padding-left: 0px;
            padding-top: 0;
          }
        }

        &__access-utility-container {
          padding: 0 15px;
          border-right: none;
        }

        &__store {
          margin-bottom: 10px;
        }

        &__search-utility-container {
          max-width: 100%;
          margin-bottom: 0px;
          border-right: none;
        }

        &__loc-search-container {
          max-width: 100%;
          padding-top: 25px;
          padding-bottom: 250px;
          padding-right: 0px;
          width: 95%;
          margin-left: 0px;
        }

        &__location-content {
          padding: 10px 0px 0px 0px;
        }

        &__zipcode-content {
          padding: 20px 0px 0px 0px;
        }

        .findstore-util-nav__search-utility-container {
          border-right: none;
          padding-right: 0px;
        }

        .findstore-util-nav__supply-section {
          padding-right: 0px;
        }

        &__input-control {
          font-size: 30px;
          border: none;
          padding-bottom: 25px;
          width: 100%;
        }

        &__input-container {
          display: flex;
          width: 100%;
          padding-top: 0px;
          flex-direction: column;
        }

        &__view-store-link {
          text-decoration: underline;
          color: $color-light-gray;
        }

        &__signStore {
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -1.9px;
        }

        &__address-container {
          margin-left: 0px;
          margin-top: 30px;
        }

        &__link-container {
          width: 100%;
        }

        &__supply-section,
        &__not-your-store {
          text-align: left;
        }

        &__mobile-container {
          padding: 17px 18px 0px;
        }

        &__close {
          margin-bottom: 36px;
        }

        &__menu {
          padding-bottom: 30px;
        }
      }

      .gbh-find-a-store {
        .findstore-util-nav {
          &__view-link-container {
            .findstore-util-nav__view-store-link {
              font-family: $font-light;
              text-decoration: underline;
              padding: 0px;
              font-size: 16px;
            }

            .view-all-stores-mobile {
              top: 30px !important;
            }
          }

          &__address-container {
            .findstore-util-nav__view-store-detail {
              font-family: $font-light;
              font-size: 16px;
            }
          }
        }
      }
    }

    .findstore-util-nav__zipcode-error {
      margin-top: 10px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.3px;
      color: $color-error-red;
    }

    .findstore-util-nav__container {
      @include breakpoint(desktop-extra-lite-sm) {
        overflow-y: scroll;
        height: 100vh;
      }

      .findstore-util-nav__link-container {
        @include breakpoint(desktop-extra-lite-sm) {
          position: relative;
        }
      }
    }
  }

  .findstore-util-nav__container {
    @include breakpoint(desktop-extra-lite-sm) {
      overflow-y: scroll;
      height: 100vh;
    }

    .findstore-util-nav__link-container {
      @include breakpoint(desktop-extra-lite-sm) {
        position: relative;
      }
    }
  }
}

// .wrapper {
//   :global(.findstore-util-nav__zipcode-error) {
//     margin-top: 10px;
//     font-family: $font-regular;
//     font-size: 16px;
//     line-height: 1.38;
//     letter-spacing: -0.3px;
//     color: $color-error-red;
//   }
// }
