@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.additionalFooterWrapper {
  :global {
    @media print {
      .additional-footer {
        display: none !important;
      }
    }
  }
}
.mainFooterWrapper {
  :global {
    @media print {
      footer {
        display: none !important;
      }
    }
  }
}
.utilityNavHeaderWrapper {
  :global {
    .gbh-utility-nav {
      .gbh-utility-navbar {
        ul {
          li {
            &.gbh-nav__findStore {
              @include breakpoint(desktop-extra-lite-sm) {
                position: fixed;
                border: 0;
                z-index: 999;
                top: 28px;
                padding: 0;
                right: 26px;
                text-align: right;
                background: $color-charcoal;
                max-height: 1000px;
                padding-right: 0 !important;
                z-index: 9999;
                left: 0;
                width: 100%;
              }
              @include breakpoint(mobile-portrait) {
                right: 1.125rem !important;
              }
              @include breakpoint(desktop-extra-lite-lg) {
                right: 2rem !important;
              }
              a {
                @include breakpoint(desktop-extra-lite-sm) {
                  position: absolute;
                }
                @include breakpoint(mobile-portrait) {
                  right: 18px !important;
                }
                @include breakpoint(mobile-landscape) {
                  right: 32px !important;
                }
                @include breakpoint(desktop-extra-lite-sm) {
                  right: 26px;
                  top: 18px;
                }
              }
              .gbh-find-a-store {
                z-index: 0 !important;
                top: -12px;
                &.active {
                  height: 100vh;
                }
              }
            }
          }
        }
      }
    }
    .country-selector {
      margin-left: 5px !important;
      padding-left: 5px !important;
      border-left: 1px solid #cdcdcd;
    }
    .selected-country {
      width: auto !important;
    }

    @include breakpoint(desktop-extra-lite-sm) {
      .gbh-utility-navbar {
        ul {
          li {
            &.gbh-nav__region {
              a {
                &.gbh-nav__region--link {
                  width: 100%;
                  &:after {
                    content: "\e92a";
                    font-family: "icomoon" !important;
                    font-size: 11px;
                    padding-left: 15px;
                    margin-top: 5px;
                    transform: rotate(268deg);
                    position: absolute;
                    right: 10px;
                    top: 27px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      .utilitynavheader {
        position: relative;
      }
    }
  }
}
