@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";
@import "@/styles/base/_fonts";
.headerNavV3Wrapper {
  :global {
    width: 100%;

    .cmp-tabs {
      &.dark-theme {
        .cmp-tabs__tablist {
          .cmp-tabs__tab {
            color: $color-white !important;
          }
        }
      }
    }
    .globalnavigation {
      .gbh-full-w-menu {
        .gbh-full-w-menu {
          overflow: scroll;
          &__inner {
            padding: 0;
            min-height: auto;
            border: none;
            ul {
              &.gbh-full-w-menu {
                overflow: scroll;
                &__left-cont {
                  &__menu-list {
                    width: auto;
                    li {
                      a {
                        font-size: 15px;
                        letter-spacing: -0.4px;
                        line-height: 22px;
                        p {
                          line-height: 22px;
                          font-size: 15px;
                        }
                      }
                    }
                    li:hover {
                      outline: none;
                    }
                  }
                }
              }
            }
            .gbh-full-w-menu {
              &__right-img {
                position: relative;
                padding-left: 60px;
                width: auto;
                .block-images {
                  p {
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                    letter-spacing: -0.45px;
                    color: $color-slate;
                  }
                }
              }
            }
            .gbh-full-max-width {
              transition: all 0.2s linear;
            }
          }
        }

        .two-landscape-images:first-child {
          margin-left: 0;
        }
        .two-landscape-images {
          width: 440px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1600px) and (max-width: 1919px) {
            width: 415px;
          }
          @media screen and (min-width: 1280px) and (max-width: 1599px) {
            width: 345px;
          }
          &.business {
            width: 440px;
            @media screen and (min-width: 1600px) and (max-width: 1919px) {
              width: 415px;
            }
            @media screen and (min-width: 1440px) and (max-width: 1599px) {
              width: 335px;
            }
            @media screen and (min-width: 1280px) and (max-width: 1439px) {
              width: 303px;
            }
          }
          a {
            img {
              padding-bottom: 10px;
            }
          }
        }

        .one-portrait-image {
          width: 340px;
          display: flex;
          flex-direction: column;
          a {
            img {
              padding-bottom: 10px;
            }
          }
        }

        .one-landscape-image {
          width: 340px;
          display: flex;
          flex-direction: column;
          a {
            img {
              padding-bottom: 10px;
            }
          }
        }
        .two-portrait-images:first-child {
          margin-left: 0;
        }
        .two-portrait-images {
          width: 340px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1440px) and (max-width: 1599px) {
            width: 305px;
          }
          @media screen and (min-width: 1280px) and (max-width: 1439px) {
            width: 250px;
          }
          &.business {
            @media screen and (min-width: 1600px) {
              width: 340px;
            }
            @media screen and (min-width: 1440px) and (max-width: 1599px) {
              width: 280px;
            }
            @media screen and (min-width: 1280px) and (max-width: 1439px) {
              width: 240px;
            }
          }
          a {
            img {
              padding-bottom: 10px;
            }
          }
        }

        .gbh-full-w-menu__inner {
          .carot-title {
            margin-bottom: -20px;
          }
          .menu-item-tiles {
            display: flex;
            width: 100%;
            align-items: center;
          }
          .menuItems {
            color: $color-charcoal;
            padding-right: 5px;
            padding-bottom: 5px;
            &.business {
              line-height: 26px;
              font-size: 18px;
              letter-spacing: -0.35px;
            }
            &.carot-icon {
              letter-spacing: -0.3px;
              font-size: 14px;
            }
          }
          p.group-title {
            padding-bottom: 0px;
          }
          .group-title {
            line-height: 22px;
            letter-spacing: -0.3px;
            color: $color-slate;
            padding-bottom: 20px;
            font-size: 14px !important;
            justify-self: left;
          }
          .navigation-img {
            display: block;
            padding-bottom: 5px;
            img {
              display: block !important;
            }
          }
          .navigation-external-img {
            img {
              display: block !important;
              padding-left: 5px;
            }
          }
          .menuItems:hover {
            text-decoration: underline;
            cursor: pointer;
          }
          .levelTwoItems {
            color: $color-charcoal !important;
          }

          .menu-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 20px 2.6% 40px;
          }
          .menu-container {
            &.dark-theme {
              li,
              ul,
              ol,
              li span,
              ul span,
              p,
              p b,
              b,
              a {
                color: $color-white;
              }
              background-color: $color-black;
              .gbh-full-w-menu__right-img {
                .block-images {
                  span p {
                    color: $color-light-gray;
                  }
                }
              }
              .group-title {
                color: $color-light-gray;
              }
              .gbh-full-w-menu__left-cont__top {
                .level-one-container {
                  li {
                    .menu-labels {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }

          .gbh-full-w-menu__left-cont {
            padding: 0;
            flex: 1;
            width: auto;
            &.products-heavy {
              @media screen and (min-width: 1920px) {
                flex: none;
              }
            }
            &.products-light {
              flex: none;
            }
            &.business {
              flex: none;
            }
            .gbh-full-w-menu__left-cont__top {
              width: 100%;
              .nav .flex-column .gbh-full-w-menu__left-cont__menu-lis {
                width: 100%;
              }
              .levelZero {
                width: 100%;
              }
              .level-one-nav-multi {
                display: flex;
                column-gap: 60px;
              }
              .level-one-container {
                display: flex;
                column-gap: 60px;
                .level-one-sub-container {
                  display: grid;
                  width: 100%;
                }
                .levelTwoMapping {
                  row-gap: 40px;
                  display: flex;
                  flex-direction: column;
                  outline: 0;
                  text-align: left;
                  width: 100%;
                  .nav-item {
                    padding-bottom: 5px;
                  }

                  &.products-light {
                    @media screen and (min-width: 1280px) and (max-width: 1439px) {
                      max-width: 197px;
                    }
                  }
                  &.business {
                    row-gap: 5px;
                    @media screen and (min-width: 1280px) and (max-width: 1439px) {
                      max-width: 197px;
                    }
                  }
                  &.business-one-col {
                    row-gap: 5px;
                  }
                }
                .menu-labels {
                  color: $color-charcoal;
                  display: flex;
                  align-items: center;
                  line-height: 22px;
                  font-size: 15px;
                  letter-spacing: -0.4px;
                }
                .menu-labels:hover {
                  text-decoration: underline;
                  font-family: $helvetica-bold;
                  cursor: pointer;
                  padding: 0;
                }
              }
            }
          }
          .gbh-full-w-menu__right-img {
            flex-direction: column;
            .right-img-cta {
              margin-bottom: 20px;
            }
            ol.multicta {
              margin-left: 0px;
              margin-bottom: 20px;
              .navigationcta {
                display: flex;
                flex-direction: row;
                height: 23px;
                align-items: center;
              }
              .ctalable {
                padding-right: 5px;
                p {
                  font-family: $helvetica-medium;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.3px;
                  color: $color-charcoal;
                }
              }
            }

            .cta-img {
              margin-bottom: 4px;
            }
            .block-images {
              display: flex;
              flex-direction: row;
            }
          }
        }
        .image-cta {
          display: flex;
          color: $color-charcoal !important;
          line-height: 22px;
          align-items: center;
          margin-top: 10px !important;
          font-size: 14px;
          font-family: $font-medium;
          padding-right: 5px;
          .div-of-cta {
            text-align: left;
            padding-right: 5px;
            letter-spacing: -0.3px;
            a {
              color: $color-charcoal;
            }
          }
          .cta-link-caret {
            padding-bottom: 4px;
          }
        }
      }
    }

    .gbh-global-tab {
      .cmp-tabs {
        &__tabpanel {
          .globalnavigation {
            .gbh-full-w-menu {
              height: auto;
              transition: height 0.2s linear;
            }
          }
        }
        &__tabpanel--active {
          .globalnavigation {
            .gbh-full-w-menu {
              overflow-y: auto;
              max-height: 75vh;
              transition: height 0.2s linear;
              .bg-dim {
                height: inherit;
              }
            }
          }
        }
        .cmp-tabs {
          &__tablist {
            .cmp-tabs {
              &__tab:hover {
                text-decoration: underline;
                font-family: $helvetica-bold;
              }
              &__tab--active {
                text-decoration: underline;
                font-family: $helvetica-bold;
                letter-spacing: -0.3px !important;
              }
              &__tab:first-child {
                margin-right: 25px;
              }
              &__tab:first-child:after {
                border-right: 0;
                position: absolute;
                height: 2pc;
                content: "";
                right: -25px;
                top: 1px;
              }
              &__tab {
                line-height: 22px;
                font-size: 15px;
                letter-spacing: -0.4px;
                padding: 19px 0px;
              }
            }
          }
        }
      }
    }
    .gbh-global-tab {
      .cmp-tabs {
        .cmp-tabs {
          &__tablist {
            .cmp-tabs {
              &__tab:hover {
                text-decoration: underline;
                font-family: $helvetica-bold;
              }

              &__tab:first-child {
                margin-right: 25px;
              }
              &__tab:first-child:after {
                border-right: 0;
                position: absolute;
                height: 2pc;
                content: "";
                right: -25px;
                top: 1px;
              }
              &__tab {
                .cmp-tabs {
                  &__tab-active {
                    text-decoration: underline;
                    font-family: $helvetica-bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
