@import "@/styles/base/_colors";

.productPerPageWrapper {
  :global {
    //products per page
    .products-per-page {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media screen and (max-width: 768px) {
        display: none;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 1.125rem;
          line-height: 1.33;
          letter-spacing: -0.45px;
          color: $color-charcoal;
          margin-right: 15px;
          cursor: pointer;

          &.disabled {
            pointer-events: none;
            color: $color-dark-gray;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
