@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.socialShareWrapper {
  :global {
    .social-share {
      position: relative;
      cursor: default;
      &__box {
        width: 257px;
        top: 5px;
        z-index: 8;
        position: absolute;
        padding: 20px 25px;
        border-radius: 5px;
        background-color: $color-white;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
      }
      &__sites {
        display: flex;
        justify-content: start;
        ul {
          list-style-type: none;
          display: inline-flex;
          margin: 0;
          overflow-x: auto;
          &::-webkit-scrollbar {
            border-radius: 4px;
            height: 7px;
          }
          &::-webkit-scrollbar-thumb {
            background: #757575;
            border-radius: 5px;
          }
          li {
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
            a {
              img {
                height: 17px;
              }
            }
          }
        }

        a {
          display: inline-block;
          img {
            pointer-events: none;
          }
        }
      }
      &__actions {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $color-dark-gray;
        display: flex;
        justify-content: space-between;
      }
      &__cta {
        cursor: pointer;
        text-decoration: none;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        margin-right: 0 !important; // to over-ride AEM component styles
        img {
          width: 20px !important;
          height: 20px;
          margin-right: 10px;
        }
        &:hover {
          text-decoration: none;
          color: $color-dark-gray;
        }
      }

      &__email {
        margin-right: 0px;
      }

      &__shared-cart-number {
        font-family: $font-bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.45px;
        color: $color-dark-gray;
      }
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      .social-share {
        &__box {
          width: auto;
          padding: 20px 5px 20px 10px !important;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
